// Style Guide Groups
export const SG_GROUP_ORDER = {
  'Corporate': 1,
  'Marketing - NOT FOR PRODUCT CREATION': 2,
  'Style Guide-Event Film Priorities': 3,
  "Style Guide-Marvel Studios' Disney Plus TV Shows": 4,
  'Style Guide-Evergreen Priorities': 5,
  'Style Guide-Opportunity': 6,
  'Style Guide-Case by Case': 7,
  'Style Guide-Out of Circulation': 8,
  'Promotional Theatrical Art Packs': 9,
  'Promotional Theatrical Art Pack-Out of Circulation': 10,
  'Style Guide-Completely Hidden Guides': 11,
  'Corporate Tool Kits': 12,
  'Evergreen Priorities Tool Kits': 13,
  'Event Film Priorities Tool Kits': 14,
  'Opportunity Tool Kits': 15
}
